import Vue from 'vue'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
// eslint-disable-next-line camelcase
import { email, max, min, min_value, numeric, required } from 'vee-validate/dist/rules'
import { regexEUNumber } from '@/shared/helpers'
import userService from '@/shared/services/userService'
import educationPassHolderService from '@/shared/services/educationPassHolderService'
import lectureService from '@/shared/services/lectureService'
import publicService from '@/shared/services/publicService'

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)
extend('min_value', min_value)

// https://stackoverflow.com/questions/59156440/how-to-validate-decimal-value-in-vee-validate-3-0-version
extend('decimal', async (value) => {
  return regexEUNumber(value) ? true : 'dialog.enter-valid-eu-number-format'
})

Vue.component('validation-provider', ValidationProvider)
Vue.component('validation-observer', ValidationObserver)

/// //// EXAMPLES: If we need custom validation checks again

// EXAMPLE: async call
extend('checkemail', async (value) => {
  const usernameCheck: any = await userService.checkIfUserWithEmailAlreadyExists(value)
  return usernameCheck.status === 200 ? true : 'email-is-already-used'
})

extend('checkemail_public', async (value, emailString) => {
  const participantCheck: any = await publicService.checkIfParticipantWithEmailAlreadyExists(value, `${emailString}`)
  return participantCheck.status === 200 ? true : 'email-is-already-used'
})

extend('checkIfNumber', async (value) => {
  return regexEUNumber(value) ? true : 'enter-valid-eu-number-format'
})

// EXAMPLE: async call
extend('checkEducationPassHolderNumber', async (value, establishmentId) => {
  const educationPassHolderNumber: any = await educationPassHolderService.checkEducationPassHolderNumber(
    `${establishmentId}`,
    value
  )
  return educationPassHolderNumber.status === 200 ? true : 'educationpassholdernumber-is-already-used'
})

// check the repeated password
extend('checkRepeatedPassword', async (value, password) => {
  return String(value).valueOf() === String(password).valueOf() ? true : 'dialog.passwords-do-not-match'
})
