import { EducationPassHolder } from '@/shared/models/educationPassHolder.model'
import { Association } from '@/shared/models/association.model'
import { LectureContent } from '@/shared/models/lectureContent.model'
import { Establishment } from '@/shared/models/establishments.model'
import axios from 'axios'
import store from '../../store'

class ResourceService {
  getResourcePaginated (query: any, resourceName: string) {
    store.dispatch(`${resourceName}Store/getList`, query)
  }

  getByEstablishmentId (resourceName: string, query: any) {
    store.dispatch(`${resourceName}Store/getSearchListByEstablishmentId`, query)
  }

  getAllResources (resourceName: string) {
    const query = {
      l: 0,
      lo: 1,
      sf: undefined,
      so: 'desc'
    }
    store.dispatch(`${resourceName}Store/getList`, query)
  }

  createResource (resource: any, resourceName: string) {
    return axios.post(`/api/${resourceName}`, resource).catch((e) => {
      console.error(`create${resourceName}() failed`, e)
    })
  }

  getResource (id: any, resourceName: string) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/${resourceName}/${id}`).then((result) => {
        if (result.status === 200 && result.data) {
          const resource: any = result.data
          resolve(resource)
        } else {
          console.error(`${resourceName} with id: ${id} not found`)
          resolve(this.getConstructor(resourceName))
        }
      })
    })
  }

  updateResource (resource: any, resourceName: string) {
    return axios.put(`/api/${resourceName}/${resource._id}`, resource).catch((e) => {
      console.error(`update${resourceName}() failed`, e)
    })
  }

  deleteResource (id: string, resourceName: string) {
    return axios.delete(`/api/${resourceName}/${id}`).catch((e) => {
      console.error(`delete${resourceName}() failed`, e)
    })
  }

  getResourceImage (id: string, resourceName: string) {
    return axios.get(`/api/file/${id}`).catch((e) => {
      console.error(`get${resourceName}Image() failed`, e)
      return e.response
    })
  }

  checkIfResourceWithEmailAlreadyExists (email: string, resourceName: string) {
    return axios.get(`/api/checkemail/${email}`).catch((e) => {
      console.error(`${resourceName} with that email already exists`, e)
    })
  }

  checkIfResourceNameExists (resourceName: string, resource: string) {
    return axios.get(`/api/check${resource}name/${resourceName}`).catch((e) => {
      console.error(`${resourceName} with that name already exists`, e)
    })
  }

  // This function has been used twice with the same purpose, therefore it is in the general service

  getEstablishmentName (id: string) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/establishment/${id}`).then((result) => {
        if (result.status === 200 && result.data) {
          const establishmentName = result.data.name
          resolve(establishmentName)
        } else {
          console.error(`Establishment with id: ${id} not found`)
          resolve(new Establishment())
        }
      })
    })
  }

  getConstructor (resourceName: string) {
    switch (resourceName) {
      case 'association':
        return new Association()
      case 'lectureContent':
        return new LectureContent()
      case 'establishment':
        return new Establishment()
      case 'educationPassHolder':
        return new EducationPassHolder()
      default:
        return null
    }
  }
}

const resourceService = new ResourceService()
export default resourceService
